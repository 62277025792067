@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.dashboard {
  background: inherit;
}
.dashboard-filter-title {
  margin-left: -50px;
}
.dashboard-panel {
  background-color: var(--color-base-background) !important;
}
