@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-panel__transparent {
  display: flex;
  column-gap: 10px;

  // 1st col
  .main-col {
    width: 70%;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 400;
    }
  }
  // 2nd col
  .right-value-col {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;

    .string {
      white-space: nowrap;
    }
  }

  // Responsive
  @media ($wx-md-min) {
    .right-value-col {
      padding-right: 1px;
    }
  }
  // @breakpoint MD ONLY
  @media ($wx-md-min) and (max-width: 1263px) {
    .main-col {
      width: 70%;
    }
    .right-value-col {
      width: 30%;
    }
  }
  @media ($wx-lg-min) {
    column-gap: 10px;

    .right-value-col {
      width: 30%;
    }
    .main-col {
      width: 70%;
    }
  }
  @media ($wx-xl-min) {
    .right-value-col {
      padding-right: 10px;
    }
  }
}
