@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.panel {
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-sm) !important;
  background-color: transparent;
  box-shadow: none;
}
.panel-title {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.panel-stats {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
}
.dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}
// --------------------------------------------------------------------------------------------------
// Uptime
// --------------------------------------------------------------------------------------------------
.uptime {
  background-color: var(--color-uptime);
}
.uptime-accessible {
  background-color: var(--color-uptimeAccessible);
}
// --------------------------------------------------------------------------------------------------
// Planned
// --------------------------------------------------------------------------------------------------
.planned {
  background-color: var(--color-plannedDowntime);
}
.planned-accessible {
  background-color: var(--color-plannedDowntimeAccessible);
}
// --------------------------------------------------------------------------------------------------
// Unplanned
// --------------------------------------------------------------------------------------------------
.unplanned {
  background-color: var(--color-justifiedDowntime);
}
.unplanned-accessible {
  background-color: var(--color-justifiedDowntimeAccessible);
}
// --------------------------------------------------------------------------------------------------
// Unjustified
// --------------------------------------------------------------------------------------------------
.unjustified {
  background-color: var(--color-unjustifiedDowntime);
}
.unjustified-accessible {
  background-color: var(--color-unjustifiedDowntimeAccessible);
}
