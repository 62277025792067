@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
  color: var(--color-base-theme);
}
.production-line-selector {
  min-height: 60px;
}
