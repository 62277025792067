@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.canvas-container {
  position: relative;
  width: 100%;
  height: 200px;
}

::v-deep canvas {
  width: 320px;
  height: 200px;
  max-width: 400px;
  margin: 0 auto;
}

.wx-panel__transparent {
  display: flex;
  column-gap: 10px;

  .main-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 5px;

    .title {
      font-weight: 400;
    }
  }

  // @breakpoint MD ONLY
  @media ($wx-md-min) and (max-width: 1263px) {
    .left-img-col {
      .v-image {
        max-width: 35px;
      }
    }
  }
}
.downtime_card {
  background-color: white !important;
}
.planned_downtime_count {
  color: var(--color-plannedDowntime);
}
.unplanned_downtime_count {
  color: var(--color-justifiedDowntime);
}
.unjustified_downtime_count {
  color: var(--color-unjustifiedDowntime);
}
.downtime_count_text {
  color: var(--color-neutral) !important;
}
