@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.graph {
  &__header {
    .select-metric {
      max-width: 10rem;
    }
    .select-weight-unit {
      max-width: 14rem;
    }
    .select-time-aggregation {
      max-width: 7rem;
    }
  }
  &__container {
    position: relative;
  }
}
.filter-style {
  padding: 0px;
}
